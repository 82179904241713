td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all ease 0.7s;
  font-size: 14px;
}
td:hover {
  max-width: 100px;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: initial;
}

p {
  white-space: normal;
}

.logo {
  width: 120px;
  height: 68.4px;
}

.card-unradius {
  border-radius: 0px !important;
}

.btn-align {
  margin-top: 2rem !important;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.navigation {
  translate: rotate(180deg);
}

.form__group {
  position: relative !important;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.form__field {
  width: 100%;
  outline: 0;
  font-size: 1.2rem;
  padding: 7px 0;
  transition: border-color 0.2s;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.2rem;
  top: 10px;
}

.form__label {
  position: absolute;
  top: -20px;
  display: block;
  font-size: 1.2rem;
  transition: 0.2s;
}

.form__field:focus {
  padding-bottom: 6px;
  border-width: 3px;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: -20px;
  display: block;
  font-size: 0.8rem;
  transition: 0.2s;
}

_form__field:required, _form__field:invalid {
  box-shadow: none;
}

table.dataTable tbody > tr.selected {
  background-color: #fcfcfc !important;
  color: black !important;
}

table.dataTable tbody > tr.investigation-row:hover {
  color: black;
  background-color: #f6f9fc;
}

table.dataTable tbody > tr.shown {
  color: black;
}

.hideElement {
  display: none !important;
}

.bg-femsa {
  background-color: #ec2227;
}
.bg-wine {
  background-color: #751d2d;
}

.footer,
body {
  background-color: #e5e5e5;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ec2227 !important;
  border-color: #ec2227 !important;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #ec2227 !important;
  border-color: #ec2227 !important;
}

ul.editable-table li.active {
  border-color: #ec2227;
  background-color: #fff;
  color: #525f7f;
}

.display-none {
  display: none;
}

.navbar-gradient {
  background: linear-gradient(to left, #ec2227, rgba(236, 34, 39, 0.86), rgba(236, 34, 39, 0.85));
}

.custom-toggle-slider {
  border: 1px solid #ec2227 !important;
  color: #ec2227 !important;
}

.custom-toggle-slider:before {
  background-color: #ec2227 !important;
}

.custom-toggle-slider:after {
  color: #ec2227 !important;
}

.custom-toggle-femsa input:checked + .custom-toggle-slider:before {
  background-color: #ec2227 !important;
}

.jvectormap-tip {
  position: absolute !important;
  display: block;
  border: solid 1px #cdcdcd;
  background: #ec2227;
  color: white;
  font-family: "Open Sans", "sans-serif" !important;
  border-radius: 5px;
  font-weight: normal;
  padding: 7px;
}

.btn-femsa {
  color: #fff !important;
  text-transform: uppercase;
  background-color: #ec2227 !important;
}
.btn-femsa:hover {
  background-color: #751d2d !important;
}

.btn-search-inv-menu {
  height: 100%;
}

.switch {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow + label {
  padding: 2px;
  width: 90px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 60px;
}

.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

.switch--shadow + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: all 0.4s;
}

.switch--shadow + label:after {
  width: 30px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch--shadow:checked + label:before {
  background-color: #8ce196;
}

.switch--shadow:checked + label:after {
  transform: translateX(60px);
}

#cancel-modification-button,
#previousButton,
#nextButton,
#end-only-gdm-button,
#advanceInvestigation,
#finishInvestigationCancel,
#finishInvestigationBack,
#finishInvestigationAdvance {
  margin-top: 0.5rem;
}

.border-femsa {
  border-color: #ec2227 !important;
  border-width: 5px !important;
}

.page-item.active .page-link {
  border-color: #ec2227;
  background-color: #ec2227;
}

.loader {
  background: url("../../images/spinner.gif") 50% 50% no-repeat white;
}

.btn-danger:not(:disabled):not(.disabled).active {
  color: #fff !important;
}

.text-femsa {
  color: #ec2227;
}

.form__field {
  border: 0;
  border-bottom: 2px solid #fff;
  color: #fff;
  background: transparent !important;
}
.form__field::placeholder {
  color: transparent;
}

.form__label {
  color: #fff;
}

.form__field:focus {
  border-image: linear-gradient(to right, #fff, #ee383d);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  color: #fff;
}

div.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  padding: 5px;
  z-index: 1;
}

a.form-nav {
  opacity: 0.4;
}
a.form-nav.active {
  opacity: 1 !important;
}

.btn-selected {
  filter: saturate(40%);
}

.switch:not(.active) {
  background-color: rgba(236, 34, 39, 0.85) !important;
}

table#table_preview th {
  position: sticky;
  top: -3px;
}
table#table_preview td:nth-child(1) {
  width: 8% !important;
}
table#table_preview td:nth-child(2) {
  width: 17% !important;
}
table#table_preview td:nth-child(3) {
  width: 15% !important;
}
table#table_preview td:nth-child(4) {
  width: 8% !important;
}
table#table_preview td:nth-child(5) {
  width: 15% !important;
}
table#table_preview tbody .green {
  border-left: 7px solid green;
}
table#table_preview tbody .yellow {
  border-left: 7px solid gold;
}
table#table_preview tbody .red {
  border-left: 7px solid red;
}

#TableInvestigations {
  height: 460px;
  overflow: auto;
}
#TableInvestigations .investigation-row {
  border-collapse: collapse;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: #eee;
}
#TableInvestigations .investigation-row:hover {
  background-color: #f6f9fc;
}

#TitleTableVoid {
  margin: auto auto;
}

.divMetas_progess {
  height: 25vh;
  align-content: center;
  justify-content: center;
  align-items: start;
  margin: auto auto;
  padding: 1.5em;
}
.divMetas_progess span {
  font-size: 12px;
  text-align: center;
}

.titleModal {
  color: red;
  text-align: center;
  margin-top: -4vh;
}

.goal-div {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
  border-radius: 6px;
  margin-bottom: 2em;
  margin-right: 1em;
}

.goal-type-container {
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 79%;
  height: 5vh;
  border-radius: 10000px;
  color: white;
  text-align: center;
  min-height: 55px;
  top: 1em;
}
.goal-type-container p {
  font-weight: bold;
  margin: auto;
}

.goal-container {
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 70%;
  border-radius: 10px;
  color: white;
  top: 6.2em;
  background-color: #7a7a7a;
  min-height: 30px;
  margin: auto;
}
.goal-container div {
  justify-content: center;
  font-size: 1.6em;
}

.div-card-certs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
  padding: 4vh;
  justify-content: center;
}

.div-card-certs .card-cert {
  flex: 1;
}

.card-cert {
  justify-self: center;
  padding: 10px;
  min-width: 200px;
  max-width: 200px;
  min-height: 115px;
  max-height: 115px;
  border: 2px solid black;
  border-radius: 8px;
  margin-bottom: 5px;
  text-align: center;
  background-color: gray;
  color: black;
}

.limit-icon-width::before {
  max-width: 14px;
}

table#tableInvestigations tbody .green {
  border-left: 5px solid green;
}
table#tableInvestigations tbody .yellow {
  border-left: 5px solid gold;
}
table#tableInvestigations tbody .red {
  border-left: 5px solid red;
}

.log-card {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 9px;
  padding: 8px;
  margin-bottom: 8px;
  transition: 0.2s;
}
.log-card:not(.active) {
  background: #ec2227;
  color: white;
}
.log-card.active {
  border: 3px solid #ec2227;
  background: white;
  color: #ec2227;
}
.log-card:hover {
  cursor: pointer;
  transform: translateX(10px);
}
.log-card .load-history {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid red;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#voidHistory > .log-card-content > p {
  margin: auto;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

.history-card {
  margin-top: 0px;
  z-index: 980;
  max-height: 60vh;
  overflow-y: auto;
}

.disabledElements {
  pointer-events: none;
  opacity: 0.9;
}

h3 {
  margin: 23px 0 10px 30px;
}

.row {
  margin: 0 15px 0 15px;
}

#field_files {
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
}

.name_file {
  cursor: pointer;
  transition: 0.3s;
}

.name_file:hover {
  opacity: 0.7;
  color: red;
}

i {
  cursor: pointer;
}

i:hover {
  opacity: 0.7;
  color: red;
}

#button_upload #label_file {
  font-size: 1.6rem;
  padding: 4px;
  right: 100%;
  bottom: 18px;
  position: absolute;
  cursor: pointer;
  transition: 0.3s;
}
#button_upload #label_file:hover {
  opacity: 0.7;
}
#button_upload #input-file {
  border: none;
  outline: none;
}

i.fa.fa-times {
  cursor: pointer;
}

.body {
  margin-top: 30px;
}
.body .nav-item a {
  opacity: 0.7;
}
.body .nav-item a.active {
  opacity: 1;
}

#table-container-cert {
  overflow-y: auto;
  max-height: 50vh;
}

.sidebar-cert {
  margin-bottom: 30px;
  align-self: flex-start;
}
.sidebar-inv {
  margin-bottom: 30px;
}
h2 {
  margin: 23px 0 10px 30px;
}

.row {
  margin: 0 15px 0 15px;
}

.container {
  margin-top: 25px;
}

#field_files {
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
}

#button_upload #label_file {
  font-size: 1.6rem;
  padding: 4px;
  right: 100%;
  bottom: 18px;
  position: absolute;
  cursor: pointer;
  transition: 0.3s;
}
#button_upload #label_file:hover {
  opacity: 0.7;
}
#button_upload #FileCertification {
  border: none;
  outline: none;
}

i.fa.fa-times {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ec2227;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ec2227;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #ec2227;
  border-color: #ec2227;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: transparent;
}

#map {
  margin: auto auto;
}

#map .state {
  cursor: pointer;
}

#map .state .shape {
  cursor: pointer;
  -width: 0;
}

#map .state .label_icon_state {
  fill: #fff;
  font-family: Arial;
  font-size: 11px;
  line-height: 12px;
  font-weight: normal;
}

#map .state .label_state {
  display: none;
  font-family: Arial;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}

#map .state:hover .label_state,
#map .state.hover .label_state {
  display: block;
}

#map .model-green .state .shape {
  fill: #e06565;
}

#map .model-green .state .icon_state {
  fill: #10592f;
}

#map .model-green .state .label_icon_state {
  fill: #fff;
}

#map .model-green .state .label_state {
  fill: #666;
}

#map .model-green .state.state_selected .shape,
#map .model-green .state:hover .shape {
  fill: black;
}

#map .model-green .state.state_disabled .shape {
  fill: gray;
}

#map .model-green .state:hover .icon_state,
#map .model-green .state.hover .icon_state {
  fill: #5a95ce;
}

#toast-info {
  background: #e0e0eb;
  border-radius: 10px;
  padding: 15px;
  width: 30vw;
  margin-left: 1vw;
}

.type1 {
  height: 3px;
  width: 25px;
  background: blue;
  border: 1px solid blue;
  margin-right: 5px;
}

.type2 {
  height: 3px;
  width: 25px;
  background: orange;
  border: 1px solid orange;
  margin-right: 5px;
}

.type3 {
  height: 3px;
  width: 25px;
  background: black;
  border: 1px solid black;
  margin-right: 5px;
}

.type4 {
  height: 3px;
  width: 25px;
  background: green;
  border: 1px solid green;
  margin-right: 5px;
}

.type5 {
  height: 3px;
  width: 25px;
  background: red;
  border: 1px solid red;
  margin-right: 5px;
}

#ReportContainer .nav a {
  opacity: 1;
}
#ReportContainer .nav a.active {
  opacity: 0.8;
}
#ReportContainer section {
  padding: 10px;
  margin-top: 10px;
  border-bottom: 1px solid red;
}
#ReportContainer .row {
  margin: 10px 0;
}
#ReportContainer .row .col-md-4 {
  font-size: 12px;
  margin-bottom: 8px;
}
#ReportContainer .tab-pane {
  width: 95%;
  margin: 35px auto;
  border: 1px solid red;
  justify-content: space-between;
}
#ReportContainer .tab-pane .container {
  width: 70%;
}
#ReportContainer .tab-pane .container_photo {
  margin: auto auto;
}
#ReportContainer .tab-pane .container_photo img {
  max-width: 225px;
  height: auto;
  object-fit: cover;
}
#ReportContainer .photo_list {
  width: 150px;
  max-height: 150px;
  object-fit: cover;
}

#dropdown-notf {
  max-height: 45vh;
}

.navcard {
  background-color: white;
  z-index: 980;
}
.navcard a {
  margin: 20px 0px 20px 0px;
  color: red !important;
}
.navcard .disabledElement {
  pointer-events: none;
  opacity: 0.9;
  background-color: #eee;
}

.container-card-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
  padding: 4vh;
  justify-content: center;
}

.container-card-info .card-info {
  flex: 1;
}

.card-info {
  justify-self: center;
  padding: 16px 10px 0px 10px;
  min-width: 25vw;
  max-width: 25vw;
  border-radius: 8px;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  margin-bottom: 5px;
  text-align: center;
  background-color: #DDD;
  color: black;
}

.invalid-select2 {
  border: 1px solid #fb6340 !important;
}
.invalid-select2 + span .select2-selection {
  border: 1px solid #fb6340 !important;
}